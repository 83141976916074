/* eslint-disable @typescript-eslint/camelcase */
import { AppService } from './AbstractService';

export default class TransactionService extends AppService {
  public requestDeposit(data: FormData) {
    return this.makeRequest('POST', '/transactions/deposit', data);
  }

  public async checkBalance() {
    return this.makeRequest('GET', '/users/balance');
  }

  public requestWithdraw(data: { amount: number }) {
    return this.makeRequest('POST', '/transactions/withdraw', data);
  }
}
